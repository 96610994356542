import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import Page from "../Components/Page";


import background from '../images/gamedev.jpg';

function Gamedev()
{
    const ffuImages = [
        require('../images/ffu/screenshot1.jpg'),
        require('../images/ffu/screenshot2.jpg'),
        require('../images/ffu/screenshot3.jpg'),
        require('../images/ffu/screenshot4.jpg'),
        require('../images/ffu/screenshot5.jpg'),
        require('../images/ffu/screenshot6.jpg'),
    ];

    const btsImages = [
        require('../images/backtoschool/screenshot1.png'),
        require('../images/backtoschool/screenshot2.png'),
        require('../images/backtoschool/screenshot3.png'),
        require('../images/backtoschool/screenshot4.png'),
        require('../images/backtoschool/screenshot5.png'),
        require('../images/backtoschool/screenshot6.png'),
        require('../images/backtoschool/screenshot7.png'),
        require('../images/backtoschool/screenshot8.png'),
    ];

    return(
        <Page background={background} title="GAMEDEV">
            <p className="smaller">Beside 2 full years in professional gamedev during which i
                participated in several projects including <a href="https://store.steampowered.com/app/1641580/Assignment_42/" target="_blank" rel="norefferer">Assignment 42</a>, 
                i also did my hobby projects: <a className="local-link" href="#ffuHeader">Fight For Ukraine</a> and <a className="local-link" href="#btsHeader">Back To School: My Worst Day</a> (mod for Max Payne).</p>
            <h2 id="ffuHeader"className="centered-text">FIGHT FOR UKRAINE</h2>
            
            <Carousel>
                <div>
                    <img src={ffuImages[0]} />
                </div>
                <div>
                    <img src={ffuImages[1]} />
                </div>
                <div>
                    <img src={ffuImages[2]} />
                </div>
                <div>
                    <img src={ffuImages[3]} />
                </div>
                <div>
                    <img src={ffuImages[4]} />
                </div>
                <div>
                    <img src={ffuImages[5]} />
                </div>
            </Carousel>



            <span className="smaller">After criminal invasion of Russian Federation to Ukraine i decided to make a browser game where you can beat war criminals down and have your own revenge.</span>
            <p className="centered-text">
                <a className='btn-link' href="https://fightforukraine.b-cdn.net/" target="_blank" rel="noopener">
                    PLAY "FIGHT FOR UKRAINE"
                </a>
            </p>
            <h2 id="btsHeader" className="centered-text">BACK TO SCHOOL: My Worst Day</h2>
            
            <Carousel>
                <div>
                    <img src={btsImages[0]} />
                </div>
                <div>
                    <img src={btsImages[1]} />
                </div>
                <div>
                    <img src={btsImages[2]} />
                </div>
                <div>
                    <img src={btsImages[3]} />
                </div>
                <div>
                    <img src={btsImages[4]} />
                </div>
                <div>
                    <img src={btsImages[5]} />
                </div>
                <div>
                    <img src={btsImages[6]} />
                </div>
                <div>
                    <img src={btsImages[7]} />
                </div>
            </Carousel>

            <span className="smaller">In late 2019 i decided to renew my school period hobby and create complete map for th classic game Max Payne. It took 3 years to finish it, but now i proudly present my "personal survival story based on real events".</span>
            <p className="centered-text">
                <a className='btn-link' href="https://www.moddb.com/mods/back-to-school-my-worst-day" target="_blank" rel="noopener">
                    "BACK TO SCHOOL" on MODDB.COM
                </a>
            </p>

            
        </Page>
    );
}

export default Gamedev;