import Page from "../Components/Page";
import Companies from "../Components/Companies";
import background from '../images/work.jpg';



function Music()
{
    return(
        <Page background={background} title="WORK"> 
            <h2>Work History</h2>
            <p>Starting in 2007 i worked more than decade in different enterprise companies including JasperSoft, 
                JustAnswer and AutoMobile Technologies. 
                In 2020 i decided to implement my childhood dream and become a game developer. 
                After working 2 years in such gamedev companies as Arrible and StarlingPlay my gestalt of making games was fulfilled and i returned to web development business.
            </p>
            <Companies/>
        </Page> 
    );
}

export default Music;