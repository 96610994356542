import classes from './EmailBox.module.css'
import {useState} from "react";
import APICaller from "../API/APICaller";
import {EnterLink} from "./EnterLink";

function EmailBox({placeholder, emailPostedHandler}) {

    const [isValid, setIsValid] = useState(true);
    const [email, setEmail] = useState('');


    function onEmailChange(event) {
        setEmail(event.target.value);
    }

    function onClickHandler() {

        const valid = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)
        setIsValid(valid);

        if(!valid) return false;

        emailPostedHandler(true);

        APICaller.addEmail(email);
    }

    return (
            <>
                <div className={classes.roundedBox}>
                    <div className={classes.joinText}>
                        Join <b>neodistinct</b> mailing list to be the first who get updates
                    </div>
                    <div>

                        <input defaultValue={email}
                            onChange={onEmailChange} className={`${classes.emailInput} ${isValid ? "" : classes.invalid}`} placeholder={placeholder} type="text" id="emailInput" />
                    </div>
                    <div className={classes.separator}></div>
                    <div>
                        <button style={{color: 'black'}} onClick={onClickHandler}>JOIN!</button>
                    </div>
                </div>
                <EnterLink/>
            </>
        );
}

export default  EmailBox;