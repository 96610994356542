import newLogo from '../icons/logo.jpg'
import './Landing.css';
import '../icons/icomoon.css'
import { Outlet } from 'react-router-dom';
import SocialMediaBar from "../Components/SocialMediaBar";
import NavigationBar from "../Components/NavigationBar";
import {Link} from 'react-router-dom';

function RootLayout({showNavigation, stretchContent, activePage}) {

    return (
        <>
            <header>
                <Link to="/">
                    <img src={newLogo} className="logo" alt="logo"/>
                    <span>neodistinct.com</span>
                </Link>

                {!showNavigation ? <div className="welcomePanel">Welcome!</div> : null}
                
                {showNavigation ? <NavigationBar activePage={activePage}/> : null }

            </header>
            <div className={`container ${stretchContent ? 'stretch': ''}`}>

                <Outlet/>

            </div>
            <footer>
                {/*Test footer*/}
                <SocialMediaBar
                    youtubeUrl = "https://www.youtube.com/@neodistinct/"
                    instagramUrl = "https://www.instagram.com/neodistinct/"
                    spotifyUrl = "https://open.spotify.com/artist/12PW234VGcnlfwP24gYgok"
                    appleMusicUrl = "https://music.apple.com/us/artist/neodistinct/1529478179"
                >
                </SocialMediaBar>
            </footer>
        </>
    );
}

export default RootLayout;
