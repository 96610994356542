import classes from "./MusicItem.module.css";

import youtubeLogo from '../../icons/colorful/youtube.png'
import appleMusicLogo from '../../icons/colorful/applemusic.png'
import spotifyLogo from '../../icons/colorful/spotify.png'
import soundcloudLogo from '../../icons/colorful/soundcloud.png'
import bandcampLogo from '../../icons/colorful/bandcamp.png'

function MusicItem({backgroundURL, youTubeUrl, appleMusicUrl, spotifyUrl,
    soundcloudUrl, bandcampUrl, isVideo}) {

    console.log("BG image:" + backgroundURL);

    return(<div className={classes.musicItem} style={{backgroundImage: 'url(' +  backgroundURL + ')'}}>

        <div className={classes.musicItemSpace}>
            { isVideo && <div className={`icon-video-camera ${classes.camera}`}/> }
        </div>
        <div className={classes.platformsNavBar}>

            { youTubeUrl && <div className={classes.platformItem}>
                <a href={youTubeUrl} target="_blank" rel="noreferrer"><img src={youtubeLogo} alt="YouTube"/></a>
            </div> }


            { appleMusicUrl && <div className={classes.platformItem}>
                <a href={appleMusicUrl} target="_blank" rel="noreferrer"><img src={appleMusicLogo} alt="Apple Music"/></a>
            </div> }

            { spotifyUrl && <div className={classes.platformItem}>
                <a href={spotifyUrl} target="_blank" rel="noreferrer"><img src={spotifyLogo} alt="Spotify"/></a>
            </div> }

            { soundcloudUrl && <div className={classes.platformItem}>
                <a href={soundcloudUrl} target="_blank" rel="noreferrer"><img src={soundcloudLogo} alt="Soundcloud"/></a>
            </div> }

            { bandcampUrl && <div className={classes.platformItem}>
                <a href={bandcampUrl} target="_blank" rel="noreferrer"><img src={bandcampLogo} alt="Bandcamp"/></a>
            </div> }
        </div>

    </div>);
}

export default MusicItem;