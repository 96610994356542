import classes from './Page.module.css'

function Page(props) {

    return (
        <>
            <article className={classes.articleContainer}>
                <div className={classes.pageHeader} style={{backgroundImage: `url('${props.background}')`}}>
                    <div className={classes.overlay}></div>
                    <h1>{props.title}</h1>
                </div>
                <div className={classes.pageBody}>
                    <div className={classes.centeredBlock}>
                        {props.children}
                    </div>
                </div>
            </article>
        </>
    )
}

export default  Page;