import Page from "../Components/Page";
import classes from '../Components/Page.module.css'
import '../icons/icomoon.css'
import background from '../images/main.jpg';

function Home()
{
    return(
        <Page background={background} title="INTRO"> 
            <h2>Introduction</h2>
            <p>Hi there!</p> I am <b>neodistinct</b>, professsional software developer with 10+ years of experience and hobby musician. <br/>
            Welcome to my personal home page.
            <p style={{marginBottom: '0'}}>Explore results of my activities in each corresponding category on the site.</p>

            <div className={`icon-peaked-cap ${classes.animatedSprite}`}>
                <span className="path1"></span><span className="path2"></span><span
                className="path3"></span><span className="path4"></span><span className="path5"></span><span
                className="path6"></span><span className="path7"></span><span className="path8"></span><span
                className="path9"></span>
            </div>

        </Page>
    );
}

export default Home;