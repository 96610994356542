import classes from './EmailBox.module.css'
import {EnterLink} from "./EnterLink";

function MessageBox(props) {

    return (
        <>
            <div className={classes.roundedBox}>
                <div className={classes.thanksText}>
                    <span className="icon-checkmark-outline"></span> {props.children}
                </div>
                <div className={classes.subtitle}>
                    Now everything in your life will be good.
                </div>

            </div>
            <EnterLink/>
        </>
        );
}

export default  MessageBox;