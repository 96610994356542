import classes from './VideoBackground.module.css'

function VideoBackground(props) {
    return (
        // test video id Yj2iELI6OeI
        //  neodistinct video id WU9-VJH64Eg

        <>

            <iframe className={classes['video-background']}
                    src={`https://www.youtube.com/embed/${props.videoId}?&autoplay=1&mute=1&playsinline=1&playlist=${props.videoId}&loop=1`}>

            </iframe>

            <div className={classes.overlay}>

            </div>

        </>
    );
}

export default VideoBackground;