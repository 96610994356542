import classes from './NavigationBar.module.css'
import {Link} from 'react-router-dom';

function NavigationBar({activePage}) {

    return (
        <div className={classes.navPanel}>
            <div className={`${classes.navItem} ${activePage === 'home' ? classes.active : ''}`}>
                <Link to="/home">INTRO</Link>
            </div>
            <div className={`${classes.navItem} ${activePage === 'work' ? classes.active: ''}`}>
                <Link to="/work">WORK</Link>
            </div>
            <div className={`${classes.navItem} ${activePage === 'gamedev' ? classes.active : ''}`}>
                <Link to="/gamedev">GAMEDEV</Link>
            </div>
            <div className={`${classes.navItem} ${activePage === 'music' ? classes.active : ''}`}>
                <Link to="/music">MUSIC</Link>
            </div>
            <div className={`${classes.navItem} ${activePage === 'contact' ? classes.active : ''}`}>
                <Link to="/contact">CONTACT</Link>
            </div>
        </div>
    );
}

export default  NavigationBar;