import './index.css';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {createBrowserRouter, RouterProvider} from 'react-router-dom';

import Landing from './Routes/Landing';
import RootLayout from "./Routes/RootLayout";

import Home from "./Pages/Home";
import Music from "./Pages/Music";
import Gamedev from "./Pages/Gamedev";
import Work from "./Pages/Work";
import Contact from "./Pages/Contact";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
    {
        path: '/',
        element: <RootLayout/>,
        errorElement: <b>Bad request.</b>,
        children: [
            {
                path: '/',
                element: <Landing/>
            },
            {
                path: 'test',
                element: <b>OK!</b>

            }
        ]
    },
    {
        path: "/home",
        element: <RootLayout activePage="home" showNavigation={true} stretchContent={true}/>,
        children: [
            {
                path: '/home',
                element: <Home/>
            }
        ]

    },
    {
        path: "/music",
        element: <RootLayout activePage="music" showNavigation={true} stretchContent={true}/>,
        children: [
            {
                path: '/music',
                element: <Music/>
            }
        ]

    },
    {
        path: "/work",
        element: <RootLayout activePage="work" showNavigation={true} stretchContent={true}/>,
        children: [
            {
                path: '/work',
                element: <Work/>
            }
        ]

    },
    {
        path: "/gamedev",
        element: <RootLayout activePage="gamedev" showNavigation={true} stretchContent={true}/>,
        children: [
            {
                path: '/gamedev',
                element: <Gamedev/>
            }
        ]

    },
    {
        path: "/contact",
        element: <RootLayout activePage="contact" showNavigation={true} stretchContent={true}/>,
        children: [
            {
                path: '/contact',
                element: <Contact/>
            }
        ]

    }
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
