import classes from './Companies.module.css'

import jasper from '../images/companies/Jaspersoft.png';
import justautarchy from '../images/companies/JustAnswer.png';
import AMT from '../images/companies/AMT.png';
import arrible from '../images/companies/Arrible.png';

function Companies() {
    return (<div className={classes['companies-panel']}>
        <a href='https://www.jaspersoft.com/' target="_blank" rel="noopener"><img src={jasper} alt="JasperSoft"></img></a>
        <a href='https://www.justanswer.com/'target="_blank" rel="noopener"><img src={justautarchy} alt="JustAnswer"></img></a>
        <a href='https://amt.company/' target="_blank" rel="noopener"><img src={AMT} alt="AutoMobile Technologies"></img></a>
        <a href='https://www.arrible.com/' target="_blank" rel="noopener"><img src={arrible} alt="Arrilbe"></img></a>
    </div>);
}

export default Companies;