import './Landing.css';
import '../icons/icomoon.css'

import EmailBox from "../Components/EmailBox";
import MessageBox from "../Components/MessageBox";
import VideoBackground from "../Components/VideoBackground";

import { useState } from "react";

function Landing() {

    const [isEmailPosted, setEmailPosted] = useState(false);

  return (
    <>
            <VideoBackground videoId="YkYT75KiBe4"></VideoBackground>

            {isEmailPosted ? <MessageBox>Thanks for joining neodistinct mailing list!</MessageBox>
                : <EmailBox placeholder="goodperson@world.com" emailPostedHandler={setEmailPosted}></EmailBox>
            }
            <br/>

    </>
  );
}

export default Landing;
