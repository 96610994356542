import classes from './SocialMediaBar.module.css'



function SocialMediaBar(props) {

    function clickHandler(event){
        window.open(event.target.dataset.url);
    }

    return (

        <div className={classes["social-media-bar"]}>

            <div title="YouTube" data-url={props.youtubeUrl} onClick={clickHandler}
                 className={`${classes["social-media-button"]} icon-youtube`}></div>

            <div title="Instagram" data-url={props.instagramUrl} onClick={clickHandler}
                 className={`${classes["social-media-button"]} icon-instagram`}></div>


            <div title="Spotify" data-url={props.spotifyUrl} onClick={clickHandler}
                 className={`${classes["social-media-button"]} icon-spotify`}></div>

            <div title="Apple Music" data-url={props.appleMusicUrl} onClick={clickHandler}
                 className={`${classes["social-media-button"]} icon-applemusic`}></div>
        </div>
    );
}

export default SocialMediaBar;