import Page from "../Components/Page";
import MusicItem from "../Components/MusicItem/MusicItem"
import background from '../images/music.jpg';
import classes from "../Components/Page.module.css";

function Music()
{
    const neodistinctMusicItems = [
        {
            title: 'neodistinct - 2X20(Aftermath)',
            youtubeUrl: 'https://youtu.be/WU9-VJH64Eg',
            appleMusicUrl: 'https://music.apple.com/us/album/2x20-aftermath-single/1531359380',
            spotifyUrl: 'https://open.spotify.com/track/4oITVarZxHvmzp6RbgTlOt',
            cover: require('../images/covers/neodistinct/aftermath.jfif'),
            isVideo: true
        },
        {
            title: 'neodistinct - PS2',
            youtubeUrl: 'https://youtu.be/jWBZySenXsc',
            appleMusicUrl: 'https://music.apple.com/us/album/ps2-single/1560236166',
            spotifyUrl: 'https://open.spotify.com/track/5ap749Yngx73kIjuJS4THe',
            cover: require('../images/covers/neodistinct/ps2.jfif'),
            isVideo: true
        },
        {
            title: 'neodistinct - NXTHING',
            youtubeUrl: 'https://youtu.be/l5c-EoiCnZA',
            appleMusicUrl: 'https://music.apple.com/us/album/nxthing-single/1648374487',
            spotifyUrl: 'https://open.spotify.com/track/4GrnsdEb9NKVYSdMjgJmvE',
            cover: require('../images/covers/neodistinct/nxthing.jfif'),
            isVideo: true
        },
        {
            title: 'neodistinct - OLD MAN LOGAN',
            youtubeUrl: 'https://youtu.be/IZzWqqSkjLM',
            appleMusicUrl: 'https://music.apple.com/us/album/old-man-logan-single/1656340681',
            spotifyUrl: 'https://open.spotify.com/track/5KyAs4rwzYtGtLN4aefflr',
            cover: require('../images/covers/neodistinct/oldmanlogan.jfif'),
            isVideo: true
        },
        {
            title: 'neodistinct - GENESIS DOES',
            youtubeUrl: 'https://youtu.be/HrK0zFQr5fY',
            appleMusicUrl: 'https://music.apple.com/us/album/genesis-does-single/1587166914',
            spotifyUrl: 'https://open.spotify.com/track/4dSeMqXZqg7tySDAE8H6DS',
            cover: require('../images/covers/neodistinct/genesisdoes.jfif'),
            isVideo: true
        },
        {
            title: 'neodistinct - TikTok',
            youtubeUrl: 'https://youtu.be/etmk7xLIJFg',
            appleMusicUrl: 'https://music.apple.com/us/album/tiktok-single/1630323556',
            spotifyUrl: 'https://open.spotify.com/track/5iaXSwKo0mzcLLnDzeVt3C',
            cover: require('../images/covers/neodistinct/tiktok.jfif'),
            isVideo: true
        },
        {
            title: 'neodistinct - MAYDAY',
            youtubeUrl: 'https://youtu.be/ez3Ut4LUCUw',
            appleMusicUrl: 'https://music.apple.com/us/album/mayday-single/1660951522',
            spotifyUrl: 'https://open.spotify.com/track/19zhCMxfsy4ts7glgL4rMj',
            cover: require('../images/covers/neodistinct/mayday.jfif'),
            isVideo: true
        }
    ];
    const distinctMusicItems = [
        {
            title: 'DISTiNcT - Втрата _ (VIDEO)',
            youtubeUrl: 'https://youtu.be/SSS6Vlex7-E',
            cover: require('../images/covers/DISTiNcT/vtrata_video.jpg'),
            isVideo: true
        },
        {
            title: 'DISTiNcT - Труднощі спілкування _ (VIDEO)',
            youtubeUrl: 'https://youtu.be/eWehoPXDpS4',
            cover: require('../images/covers/DISTiNcT/trud_video.jpg'),
            isVideo: true
        },
        {
            title: 'DISTiNcT - Мішень _ (VIDEO)',
            youtubeUrl: 'https://youtu.be/MUbLs-1rVUU',
            cover: require('../images/covers/DISTiNcT/mishen_video.jpg'),
            isVideo: true
        },
        {
            title: 'DISTiNcT - Порожнеча _ (EP)',            
            appleMusicUrl: 'https://music.apple.com/ca/album/%D0%BF%D0%BE%D1%80%D0%BE%D0%B6%D0%BD%D0%B5%D1%87%D0%B0/1511879876',
            spotifyUrl: 'https://open.spotify.com/album/1L8q8KG7kLL0VLvvVcn9Ka',
            bandcampUrl: 'https://distinctua.bandcamp.com/album/-',
            cover: require('../images/covers/DISTiNcT/youareempty.jfif')
        },
        {
            title: 'DISTiNcT - Причина Ненавидіти _ (EP)',
            appleMusicUrl: 'https://music.apple.com/mw/album/%D0%BF%D1%80%D0%B8%D1%87%D0%B8%D0%BD%D0%B0-%D0%BD%D0%B5%D0%BD%D0%B0%D0%B2%D0%B8%D0%B4%D1%96%D1%82%D0%B8/1324571957',
            spotifyUrl: 'https://open.spotify.com/album/2qgHMc2N8YDySXW369phAd',
            bandcampUrl: 'https://distinctua.bandcamp.com/album/--2',
            cover: require('../images/covers/DISTiNcT/reasontohate.jfif')
        },
        {
            title: 'DISTiNcT - Точка Неповернення _ (EP)',
            soundcloudUrl: 'https://soundcloud.com/distinct_ua/sets/ep-2013',
            youtubeUrl: 'https://www.youtube.com/playlist?list=PLmpaZuCtfKIkaHpVURG4HoxjBwSdv7ROt',
            cover: require('../images/covers/DISTiNcT/noreturnpoint.jpg')
        }
    ];
    const asfixMusicItems = [
        {
            title: 'ASFIX - Асфіксія',
            youtubeUrl: 'https://www.youtube.com/watch?v=ft17RslJQQY',
            bandcampUrl: 'https://asfix.bandcamp.com/',
            soundcloudUrl: 'https://soundcloud.com/asfixproject',
            cover: require('../images/covers/ASFIX/asfixia.jpg')
        }
    ];


    return(
        <Page background={background} title="MUSIC"> 
            <h2 className="smaller-margin">neodistinct</h2>
            <p className="smaller no-top-margin">
                In 2020 after my band DISTiNcT came to it's logical ending,
                i started a solo project <b>neodistinct</b> doing songs in style of trap/cloud rap and shooting music videos
                for almost every song.
            </p>
            {/*<div className={`icon-music ${classes.animatedSpriteSmaller}`}></div>*/}
             <div className={classes.itemsContainer}>
                { neodistinctMusicItems.map(item => 
                    <MusicItem youTubeUrl={item.youtubeUrl}
                           spotifyUrl={item.spotifyUrl}
                           appleMusicUrl={item.appleMusicUrl}
                           bandcampUrl={item.bandcampUrl}
                           soundcloudUrl={item.soundcloudUrl}
                           backgroundURL={item.cover}
                           isVideo={item.isVideo}
                    />
                ) }
            </div>
            <h2 className="smaller-margin">DISTiNcT</h2>
            <p className="smaller no-top-margin">
                    Started in 2011 with Stanislav Romanyuk, Oleksiy Bartovanets and Robert Kohutka, the band,
                    style of which is characterized as nu-metal and nu-metalcore released 3 EPs and 3 music videos during it's lifespan.
            </p>
            <div className={classes.itemsContainer}>
                { distinctMusicItems.map(item =>
                    <MusicItem youTubeUrl={item.youtubeUrl}
                        spotifyUrl={item.spotifyUrl}
                        appleMusicUrl={item.appleMusicUrl}
                        bandcampUrl={item.bandcampUrl}
                        soundcloudUrl={item.soundcloudUrl}
                        backgroundURL={item.cover}
                        isVideo={item.isVideo}

                    />)

                }
            </div>
            <h2 className="smaller-margin">ASFIX</h2>
            <p className="smaller no-top-margin">
                Progressive metal band i played in for 2 years from 2008 to 2010. I was invited by band's leader Olexandr Pavay for role of vocalist
                and brought my friend Vitaliy Bartovanets to take place of second guitarist.
                Years later we decided to record a song as a memory, so with help of sound engineer Bohdan Kachur we released the one and only professional recording of ASFIX to date.
            </p>
            <div className={classes.itemsContainer}>
                { asfixMusicItems.map(item =>
                    <MusicItem youTubeUrl={item.youtubeUrl}
                         spotifyUrl={item.spotifyUrl}
                         appleMusicUrl={item.appleMusicUrl}
                         bandcampUrl={item.bandcampUrl}
                         soundcloudUrl={item.soundcloudUrl}
                         backgroundURL={item.cover}/>)
                }
            </div>
        </Page>
    );
}

export default Music;