import Page from "../Components/Page";

import background from '../images/contact.jpg';
import classes from "../Components/Page.module.css";

function Contact()
{
    return(
        <Page background={background} title="CONTACT"> 
                    <h2>Contact Information</h2>
                    For work related stuff ping me on <a href='https://linkedin.com/in/peter-pashchenko' target='_blank'>LinkedIn</a>.<br/>
                    <p>For everything else <a href='https://www.instagram.com/neodistinct/' target='_blank'>Follow On Instagram</a>.</p>

                    <div className={`icon-mail ${classes.animatedSpriteSmaller}`}>
                    </div>
        </Page>
    );
}

export default Contact;