const endpointURL = 'https://distinct-music.firebaseio.com/releases.json';

const APICaller = {
    addEmail: function (email){

        fetch(endpointURL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: email
            })
        })
        .then(response => {

            // When it is all good
            if (response.ok) {

                console.log("Email stored.");
                return response.json();
            }
            throw new Error('Network response was not ok.');

        }) // Output the response
        .then(data => {

            console.log('Response data:', data);

        }) // Output the error if something was not right
        .catch(error => {

            console.error('Error:', error);

        });


    }
};

export default APICaller;